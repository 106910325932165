import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4, P, A, HorizontalLine } from "@collabodoc/component-library";
import React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";

const SUPPORT_PHONE = "08 - 12 82 66 00";
const SUPPORT_EMAIL = "support@collabodoc.com";

const PhoneNumberLink = () => (
  <A href={`telto:${SUPPORT_PHONE.replace(/ /g, "")}`}>{SUPPORT_PHONE}</A>
);

const EmailLink = () => <A href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</A>;


const Footer = () => (
  <>
    <Row tag={"footer"}>
      <HorizontalLine />
      <Col>
        <H4>
          <FormattedMessage id={"support.heading"} />
        </H4>
      </Col>
    </Row>
    <Row>
      <Col>
        <P>
          <FormattedMessage
            id={"support.paragraph"}
            values={{
              phone: <PhoneNumberLink />,
              email: <EmailLink />,
            }}
          />
        </P>
        <A href="/usermanuals"><FontAwesomeIcon icon={faInfo}></FontAwesomeIcon> <FormattedMessage id="usermanuals.link" /></A>
      </Col>
    </Row>
  </>
);

export default Footer;
