import React from "react";

const SickLeaveIcon = () => (
  <svg height="40px" width="40px" viewBox="0 0 78.13 79.11">
    <path
      d="M7592.87,1739.15c-7.16-6-13.48-5.73-13.75-5.72a2.74,2.74,0,0,0-1.77.8l-26.43,26.42a2.8,2.8,0,0,0-1.05,1.11,2.7,2.7,0,0,0-.61,1.7v17.59a2.75,2.75,0,0,0,2.75,2.75h17.59a2.75,2.75,0,0,0,1.94-.8l26.91-26.91a2.67,2.67,0,0,0,.77-1.54A17.72,17.72,0,0,0,7592.87,1739.15Zm-38.11,26.95a19.2,19.2,0,0,1,7.57,4.1,12.41,12.41,0,0,1,4.42,8.1h-12Zm16.93,9a17.65,17.65,0,0,0-5.81-9.07,25.64,25.64,0,0,0-7.89-4.63l22.38-22.38a17.61,17.61,0,0,1,9,4.37,12.34,12.34,0,0,1,4.5,9.57Z"
      transform="translate(-7521.19 -1733.43)"
      fill="currentColor"
    />
    <path
      d="M7569.58,1789.21a2.75,2.75,0,0,0-2.75,2.75V1807h-40.14v-66.67h33.83a2.75,2.75,0,0,0,0-5.5h-36.58a2.75,2.75,0,0,0-2.75,2.75v72.17a2.75,2.75,0,0,0,2.75,2.75h45.64a2.75,2.75,0,0,0,2.75-2.75V1792A2.75,2.75,0,0,0,7569.58,1789.21Z"
      transform="translate(-7521.19 -1733.43)"
      fill="currentColor"
    />
    <path transform="translate(-7521.19 -1733.43)" fill="currentColor" />
    <path
      d="M7554.76,1748a2.75,2.75,0,0,0-2.75-2.75h-18.87a2.75,2.75,0,0,0,0,5.5H7552A2.75,2.75,0,0,0,7554.76,1748Z"
      transform="translate(-7521.19 -1733.43)"
      fill="currentColor"
    />
  </svg>
);

export default SickLeaveIcon;
