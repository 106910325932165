import { ptj as ptjComponentLibraryTheme } from "@collabodoc/component-library";
import logo from '../images/Logo/ptj_light.png'


const ptj = {
    ...ptjComponentLibraryTheme,
    logo: logo,
    productCard: {
        backgroundColor: ptjComponentLibraryTheme.colors.secondary,
        boxShadow: "0px 5px 15px #b1b1b1",
        disabled: {
            color: ptjComponentLibraryTheme.colors.primary,
            backgroundColor: ptjComponentLibraryTheme.colors.disabled
        },
        border: {
            color: ptjComponentLibraryTheme.colors.primary,
        },
        boxShadowColor: ptjComponentLibraryTheme.colors.primary,
        color: ptjComponentLibraryTheme.colors.white,
        hover: {
            color: ptjComponentLibraryTheme.colors.secondary,
            backgroundColor: ptjComponentLibraryTheme.colors.primary,
            border: {
                color: "#09385c"
            }
        },
    },
}

export default ptj;