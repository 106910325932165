import { defaultTheme as defaultComponentLibraryTheme } from "@collabodoc/component-library";
import logo from '../images/Logo/collabodoc_light.png'

const defaultTheme = {
  ...defaultComponentLibraryTheme,
  logo: logo,
  productCard: {
    backgroundColor: defaultComponentLibraryTheme.colors.tertiary,
    boxShadow: "0px 5px 15px #d1d1d1",
    disabled: {
      color: defaultComponentLibraryTheme.colors.primary,
      backgroundColor: defaultComponentLibraryTheme.colors.disabled
    },
    border: {
      color: defaultComponentLibraryTheme.colors.primary,
    },
    boxShadowColor: defaultComponentLibraryTheme.colors.primary,
    color: defaultComponentLibraryTheme.colors.primary,
    hover: {
      color: defaultComponentLibraryTheme.colors.secondary,
      backgroundColor: defaultComponentLibraryTheme.colors.primary,
      border: {
        color: "#09385c"
      },
    },
  },
};

export default defaultTheme;


