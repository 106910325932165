import React from "react";

const WaitingRoomIcon = () => (

    <svg width="40px" height="40px" viewBox="0 0 81.7 79.5">
        <path
            d="M79.05,30.88c0,0.8-0.31,1.61-0.92,2.22L41.67,69.57c-1.23,1.23-3.22,1.23-4.45,0l-2.5-2.5C22.99,76.15,8.98,82.96,2.54,76.51c-6.44-6.45,0.37-20.45,9.44-32.19l-2.5-2.5c-1.23-1.23-1.23-3.22,0-4.45L45.94,0.92c1.23-1.23,3.22-1.23,4.45,0l4.48,4.48c1.14,1.14,1.23,2.96,0.21,4.21C44.04,23.12,41.23,33.39,43.45,35.6c2.22,2.22,12.48-0.6,25.99-11.64c1.25-1.02,3.07-0.93,4.21,0.21l4.48,4.48C78.74,29.27,79.05,30.08,79.05,30.88z M39.44,62.9l32.02-32.02l-0.28-0.28C59.45,39.68,45.45,46.5,39,40.05c-6.45-6.45,0.36-20.45,9.44-32.18l-0.28-0.28L16.15,39.61l2.26,2.26c1.14,1.14,1.23,2.96,0.21,4.21C7.58,59.59,4.77,69.86,6.98,72.07c2.22,2.22,12.48-0.6,25.99-11.64c1.25-1.02,3.07-0.93,4.21,0.21L39.44,62.9z"
            fill="currentColor"
        />
    </svg>
);

export default WaitingRoomIcon;