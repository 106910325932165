const EXTERNAL_URLS = {
    MANAGE: `${window.REACT_APP_USERSERVICE_URL}/identity/manage`,
    USER: `${window.REACT_APP_USERSERVICE_URL}/identity/user`,
    ADDUSER: `${window.REACT_APP_USERSERVICE_URL}/identity/user/add`,
    SETTINGS: `${window.REACT_APP_USERSERVICE_URL}/identity/carecenter/settings`,
    ADDCARECENTER: `${window.REACT_APP_USERSERVICE_URL}/identity/carecenter/add`
}

const USER_SERVICE_API = `${window.REACT_APP_USERSERVICE_URL}/api`;

const API_URLS = {
    GET_CARECENTERS: `${USER_SERVICE_API}/carecenters/`,
};

export {EXTERNAL_URLS, API_URLS};
