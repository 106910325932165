import React from "react";

const VaccinationIcon = () => (
  <svg width="40px" height="40px" viewBox="0 0 73.37 72.44">
    <path
      d="M37.35,12.61a2.75,2.75,0,0,0-3.93,3.86L36,19.12l-30.46,30a2.68,2.68,0,0,0-.77,1.44l-2.11,11A2.72,2.72,0,0,0,3.43,64l.6.61L.82,67.73a2.75,2.75,0,1,0,3.86,3.92l3.2-3.15.61.62a2.77,2.77,0,0,0,2.43.78L22,68a2.75,2.75,0,0,0,1.45-.75L53.88,37.29l2.61,2.65a2.75,2.75,0,0,0,3.89,0,2.75,2.75,0,0,0,0-3.89l-4.53-4.61-14-14.24ZM50,33.36,45.8,37.52,41.27,32.9a2.75,2.75,0,0,0-3.92,3.86l4.53,4.61-4,3.92-4.53-4.61a2.75,2.75,0,1,0-3.92,3.86L34,49.15l-4,3.92-4.53-4.61a2.75,2.75,0,1,0-3.93,3.85l4.54,4.62L20.18,62.7,11.4,64.23l-3-3.09L10,52.39,39.89,23Z"
      fill="currentColor"
    />
    <path
      d="M55.49.79a2.76,2.76,0,0,0,0,3.89L56.7,5.93l-7.65,7.52a2.75,2.75,0,0,0,0,3.89l7.07,7.19a2.75,2.75,0,0,0,3.89,0l7.65-7.52,1,1.06a2.75,2.75,0,0,0,3.89,0,2.75,2.75,0,0,0,0-3.89l-3-3L62.55,4h0L59.38.82A2.75,2.75,0,0,0,55.49.79Zm8.27,12.34-5.68,5.59-3.22-3.27,5.69-5.59Z"
      fill="currentColor"
    />
  </svg>
);

export default VaccinationIcon;
