import React from "react";

const DropInIcon = () => (
  <svg width="40px" height="40px" viewBox="0 0 81.7 79.5">
    <path
      d="M81.7,29.1c0-6.8-5.5-12.2-12.2-12.2s-12.2,5.5-12.2,12.2c0,5.8,4.1,10.7,9.5,11.9v13.4  c0,10.7-8.7,19.4-19.4,19.4c0,0,0,0,0,0c-10.7,0-19.4-8.7-19.4-19.4V43.6c12.4-1.4,22.1-12,22.2-24.8v-16c0,0,0,0,0,0c0,0,0,0,0,0  c0-1.5-1.3-2.8-2.8-2.8h-7.6c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.2,2.8,2.8,2.8h4.8v13.2c0,10.7-8.6,19.3-19.2,19.4c0,0-0.1,0-0.1,0  c0,0-0.1,0-0.1,0c-10.7,0-19.4-8.7-19.4-19.4V5.6h4.8c1.5,0,2.8-1.3,2.8-2.8c0-1.5-1.3-2.8-2.8-2.8H2.8C1.3,0,0,1.3,0,2.8v16  c0,12.9,9.8,23.5,22.3,24.8v10.8c0,13.8,11.2,25,25,25c0,0,0,0,0,0c0,0,0,0,0,0c13.8,0,24.9-11.2,25-25V41  C77.7,39.7,81.7,34.9,81.7,29.1z M76.1,29.2c0,3.5-2.6,6.3-6,6.6c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.6,0.1  c-3.5-0.2-6.2-3.1-6.2-6.6c0-3.7,3-6.6,6.6-6.6C73.1,22.5,76.1,25.5,76.1,29.2C76.1,29.1,76.1,29.1,76.1,29.2z"
      fill="currentColor"
    />
  </svg>
);

export default DropInIcon;
