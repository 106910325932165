import React from "react";

const LogsIcon = () => (
  <svg width="40px" height="40px" viewBox="0 0 81.07 81.08">
    <path
      d="M7429.83,1097a12.28,12.28,0,1,0,12.28,12.28A12.29,12.29,0,0,0,7429.83,1097Zm0,19a6.78,6.78,0,1,1,6.78-6.77A6.78,6.78,0,0,1,7429.83,1116Z"
      transform="translate(-7404.61 -1053.39)"
      fill="currentColor"
    />
    <path
      d="M7483.41,1055.66a7.67,7.67,0,0,0-5.48-2.27h-12.71a7.73,7.73,0,0,0-5.48,2.27l-21.19,21.19a29.09,29.09,0,1,0,23.68,23.68l2.87-2.87a2.79,2.79,0,0,0,.8-2v-7.14h7.15a2.75,2.75,0,0,0,2.75-2.75v-7.15h7.13l.33,0,.23,0,.36-.12.15,0a3.11,3.11,0,0,0,.4-.22l.07,0a2,2,0,0,0,.36-.3l.05,0c.09-.1.17-.21.25-.31l.08-.1a2.85,2.85,0,0,0,.16-.3,1.4,1.4,0,0,0,.09-.17c0-.09.06-.18.09-.27a2.25,2.25,0,0,0,.08-.24c0-.09,0-.19,0-.28a2.4,2.4,0,0,0,0-.28v-14.77A7.71,7.71,0,0,0,7483.41,1055.66ZM7473,1073.18a2.75,2.75,0,0,0-2.74,2.75v7.14h-7.15a2.75,2.75,0,0,0-2.75,2.75v8.76l-2.95,3-.07.09a2.72,2.72,0,0,0-.81,2.61,23.51,23.51,0,0,1-22.94,28.74,23.52,23.52,0,0,1,0-47,23.66,23.66,0,0,1,5.22.58h0l.33,0,.21,0h0l.3,0a.71.71,0,0,0,.2,0c.12,0,.23-.06.35-.09l.16,0,.3-.15a1.51,1.51,0,0,0,.17-.1,1.46,1.46,0,0,0,.21-.16,1.74,1.74,0,0,0,.22-.18l0,0,22.23-22.23a2.25,2.25,0,0,1,1.59-.66h12.71a2.25,2.25,0,0,1,2.25,2.25v12Z"
      transform="translate(-7404.61 -1053.39)"
      fill="currentColor"
    />
  </svg>


);

export default LogsIcon;
