import {capio as capioComponentLibraryTheme,} from "@collabodoc/component-library";
import logo from '../images/Logo/capio_light.png'

const capioTheme = {
  ...capioComponentLibraryTheme,
  logo: logo,
  productCard: {
    backgroundColor: capioComponentLibraryTheme.colors.secondary,
    boxShadow: "0px 5px 15px #d1d1d1",
    disabled: {
      color: capioComponentLibraryTheme.colors.primary,
      backgroundColor: capioComponentLibraryTheme.colors.disabled
    },
    border: {
      color: capioComponentLibraryTheme.colors.primary,
    },
    boxShadowColor: capioComponentLibraryTheme.colors.primary,
    color: capioComponentLibraryTheme.colors.primary,
    hover: {
      color: capioComponentLibraryTheme.colors.secondary,
      backgroundColor: capioComponentLibraryTheme.colors.primary,
      border: {
        color: "#09385c"
      }
    },

  }
};

export default capioTheme;


