import { meliva as melivaComponentLibraryTheme } from "@collabodoc/component-library";
import logo from '../images/Logo/meliva_light.png'


const meliva = {
    ...melivaComponentLibraryTheme,
    logo: logo,
    productCard: {
        backgroundColor: "#e0efec",
        boxShadow: "0px 5px 15px #d1d1d1",
        disabled: {
            color: melivaComponentLibraryTheme.colors.tertiary,
            backgroundColor: melivaComponentLibraryTheme.colors.disabled
        },
        border: {
            color: melivaComponentLibraryTheme.colors.primary,
        },
        boxShadowColor: melivaComponentLibraryTheme.colors.primary,
        color: '#000',
        hover: {
            color: melivaComponentLibraryTheme.colors.secondary,
            backgroundColor: melivaComponentLibraryTheme.colors.primary,
            border: {
                color: "#09385c"
            }
        },

    }
}

export default meliva;