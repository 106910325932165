import {
    AuthenticationContext,
    AuthenticationProvider,
    oidcLog,
    withOidcSecure,
} from "@axa-fr/react-oidc-context";
import React, { useContext, useEffect } from "react";
import { LoadingView } from "@collabodoc/component-library";
import PropTypes from "prop-types";

const RegisterEvents = ({ children }) => {
    const context = useContext(AuthenticationContext);

    useEffect(() => {
        const { events, userManager, logout } = context;
        const userSessionChanged = async () => {
            //  Trigger refresh of user
            await userManager.signinSilent();
        };
        const userSignedOut = async () => {
            //  Ensure that authentication state is cleaned up if user logs out.
            await logout();
        };

        events.addUserSessionChanged(userSessionChanged);
        events.addUserSignedOut(userSignedOut);

        return () => {
            events.removeUserSessionChanged(userSessionChanged);
            events.removeUserSignedOut(userSignedOut);
        };
    }, [context]);

    return <>{children}</>;
};

RegisterEvents.propTypes = {
    children: PropTypes.node.isRequired,
};

const Auth = ({ children, config, loggerLevel = oidcLog.WARN }) => {
    return (
        <AuthenticationProvider
            configuration={config}
            loggerLevel={loggerLevel}
            notAuthenticated={NotAuthenticated}
            notAuthorized={NotAuthorized}
            authenticating={Authenticating}
            sessionLostComponent={SessionLost}
            callbackComponentOverride={Callback}
        >
            <RegisterEvents>
                <WithOidcSecure>{children}</WithOidcSecure>
            </RegisterEvents>
        </AuthenticationProvider>
    );
};

const WithOidcSecure = withOidcSecure(({ children }) => children);

const NotAuthenticated = () => {
    window.location.replace(
        window.location.protocol + "//" + window.location.host
    );
    return <LoadingView />;
};

const NotAuthorized = () => (
    <LoadingView message="Du får inte se den här sidan" />
);

const Authenticating = () => (
    <div>
        <LoadingView />
    </div>
);

const SessionLost = () => {
    return (
        <div>
            <h1 className="textCenter marginTop50">Du har blivit utloggad</h1>
            <button
                onClick={() => (window.location.href = "/")}
                className="btn btn-primary "
            >
                Gå till startsidan
            </button>
        </div>
    );
};

const Callback = () => (
    <div>
        <LoadingView />
    </div>
);

Auth.propTypes = {
    children: PropTypes.node.isRequired,
    config: PropTypes.object.isRequired,
    loggerLevel: PropTypes.number,
};
export { Auth };
