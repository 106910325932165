import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  CareCenterContext,
  H1,
  H2,
  H3,
  H5,
  HorizontalLine,
  LoadingView,
  PrimaryButton,
  SecondaryButton,
} from "@collabodoc/component-library";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { isIE } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPlayer from "react-player/youtube";
import styled from "styled-components";
import AlertMessages from "./AlertMessages";
import { getAlerts, getReleaseNotes } from "./Blob";
import { DROPIN_SETTING, PRODUCT } from "./constants/products";
import ReleaseNotes from "./ReleaseNotes";
import "./style/custom.css";
import { EXTERNAL_URLS } from "./urls";
import {
  contactIcon,
  contactPicture,
  digitalFirstIcon,
  digitalFirstPicture,
  dropInIcon,
  dropInPicture,
  logsIcon,
  logsPicture,
  medicalRoundIcon,
  medicalRoundPicture,
  registratorIcon,
  registratorPicture,
  settingsIcon,
  settingsPicture,
  sickLeaveIcon,
  sickLeavePicture,
  userIcon,
  userPicture,
  vaccinationIcon,
  vaccinationPicture,
  videoPicture,
  videoIcon,
  waitingRoomIcon,
  waitingRoomPicture,
} from "./utils/imageUtils";
import { ADMINISTRATORROLE, hasSomeRole, useRoles } from "./utils/roleUtils";

const getCareCenterProduct = (careCenter, productId) =>
  careCenter &&
  careCenter.products.find((product) => product.productId === productId);
const getDropInSetting = (product, settingId) =>
  product &&
  product.productSettings.find((product) => product.settingId === settingId)
    .value;

const ProductsView = () => {
  const oidc = useReactOidc();
  const intl = useIntl();
  const { hasClinicRole, hasRegistratorRole, hasAdminRole } = useRoles(
    (oidc && oidc.oidcUser && oidc.oidcUser.profile.role) ?? []
  );
  const [showSickLeaveModal, setShowSickLeaveModal] = useState(false);
  const [showDigitalContactModal, setShowDigitalContactModal] = useState(false);
  const [showSithModal, setShowSithModal] = useState(true);

  const {
    careCenters,
    isLoadingCareCenters,
    currentCareCenterId,
    setCurrentCareCenter,
  } = useContext(CareCenterContext);
  const isClinicUser = hasClinicRole();
  const isAdminUser = hasAdminRole();
  const superUserPrivilegesRoles = [
    ADMINISTRATORROLE.SUPERUSER,
    ADMINISTRATORROLE.MANAGER,
  ];
  const hasSuperUserPrivileges = hasSomeRole(
    oidc?.oidcUser?.profile?.role,
    superUserPrivilegesRoles
  );
  const isRegistratorUser = hasRegistratorRole();
  const [selectedCareCenter, setSelectedCareCenter] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const [releaseNotes, setReleaseNotes] = useState(null);

  useEffect(() => {
    getAlerts(currentCareCenterId).then((result) => setAlerts(result));
    getReleaseNotes().then((result) => setReleaseNotes(result));
  }, [currentCareCenterId]);

  const dropInProduct = getCareCenterProduct(
    selectedCareCenter,
    PRODUCT.DROP_IN
  );
  const digitalContactProduct = getCareCenterProduct(
    selectedCareCenter,
    PRODUCT.CONTACT
  );
  const sickLeaveProduct = getCareCenterProduct(
    selectedCareCenter,
    PRODUCT.SICK_LEAVE
  );
  const vaccinationProduct = getCareCenterProduct(
    selectedCareCenter,
    PRODUCT.VACCINATION
  );
  const digitalFirstProduct = getCareCenterProduct(
    selectedCareCenter,
    PRODUCT.DIGITAL_FIRST
  );
  const medicalRoundProduct = getCareCenterProduct(
    selectedCareCenter,
    PRODUCT.MEDICAL_ROUND
  );
  const videoProduct = getCareCenterProduct(selectedCareCenter, PRODUCT.VIDEO);

  const hasWaitingRoom = getDropInSetting(
    dropInProduct,
    DROPIN_SETTING.WAITING_ROOM
  );
  const remoteRegistrationEnabled = getDropInSetting(
    dropInProduct,
    DROPIN_SETTING.REMOTE_REGISTRATION
  );

  useEffect(() => {
    if (careCenters.length >= 1 && !currentCareCenterId) {
      setSelectedCareCenter(careCenters[0]);
      setCurrentCareCenter(careCenters[0].id);
    } else if (careCenters) {
      const cc = careCenters.find((cc) => cc.id === currentCareCenterId);
      setSelectedCareCenter(cc);
    }
  }, [careCenters, currentCareCenterId, setCurrentCareCenter]);

  if (isLoadingCareCenters) {
    return <LoadingView />;
  }

  const dropInLinks = dropInProduct && [
    {
      showLink: isClinicUser || isAdminUser,
      url: `${dropInProduct.productUrl}/PatientList`,
      title: intl.formatMessage({ id: "dropin.patientlist" }),
    },
    {
      showLink: true,
      url: `${dropInProduct.productUrl}/Statistics`,
      title: intl.formatMessage({ id: "dropin.statistics" }),
    },
    {
      showLink: remoteRegistrationEnabled,
      url: `${dropInProduct.productUrl}/Patient/RegistrationAndCheckIn`,
      title: intl.formatMessage({ id: "dropin.patientregandcheckin" }),
    },
    {
      showLink: !remoteRegistrationEnabled,
      url: `${dropInProduct.productUrl}/Patient/SelectSymptomForm`,
      title: intl.formatMessage({ id: "dropin.patientregistration" }),
    },
  ];

  const digitalContactLinks = digitalContactProduct && [
    {
      showLink: true,
      url: `${digitalContactProduct.productUrl}/carecenters/${currentCareCenterId}`,
      title: intl.formatMessage({ id: "contact.issuelist" }),
    },
    {
      showLink: true,
      url: `${digitalContactProduct.productUrl}/carecenters/${currentCareCenterId}/statistics`,
      title: intl.formatMessage({ id: "contact.statistics" }),
    },
  ];

  const sickLeaveLinks = sickLeaveProduct && [
    {
      showLink: true,
      url: `${sickLeaveProduct.productUrl}/PatientList`,
      title: intl.formatMessage({ id: "sickleave.patientlist" }),
    },
  ];

  const vaccinationLinks = vaccinationProduct && [
    {
      showLink: isClinicUser || isAdminUser,
      url: `${vaccinationProduct.productUrl}/PatientList`,
      title: intl.formatMessage({ id: "vaccination.patientlist" }),
    },
    {
      showLink: isClinicUser || isAdminUser,
      url: `${vaccinationProduct.productUrl}/Schedule/Index?carecenterid=${currentCareCenterId}`,
      title: intl.formatMessage({ id: "vaccination.schedule" }),
    },
    {
      showLink: isClinicUser || isAdminUser,
      url: `${vaccinationProduct.productUrl}/NewPatient`,
      title: intl.formatMessage({ id: "vaccination.registration" }),
    },
    {
      showLink: true,
      url: `${vaccinationProduct.productUrl}/Statistics`,
      title: intl.formatMessage({ id: "vaccination.statistics" }),
    },
  ];

  const digitalFirstLinks = digitalFirstProduct && [
    {
      showLink: true,
      url: `${digitalFirstProduct.productUrl}/PatientList`,
      title: intl.formatMessage({ id: "digitalfirst.patientlist" }),
    },
  ];

  const medicalRoundLinks = medicalRoundProduct && [
    {
      showLink: true,
      url: `${medicalRoundProduct.productUrl}`,
      title: intl.formatMessage({ id: "medicalround.patientlist" }),
    },
  ];

  const videoLinks = videoProduct && [
    {
      showLink: true,
      url: `${videoProduct.productUrl}`,
      title: intl.formatMessage({ id: "video.dashboard" }),
    },
  ];

  const adminLinks = [
    {
      showLink: true,
      url: `${EXTERNAL_URLS.USER}?carecenterid=${currentCareCenterId}&returnurl=${window.location.href}`,
      title: intl.formatMessage({ id: "user.userlist" }),
    },
    {
      showLink: true,
      url: `${EXTERNAL_URLS.ADDUSER}?carecenterid=${currentCareCenterId}&returnurl=${window.location.href}`,
      title: intl.formatMessage({ id: "user.adduser" }),
    },
  ];

  const settingsLinks = [
    {
      showLink: true,
      url: `${EXTERNAL_URLS.SETTINGS}?carecenterid=${currentCareCenterId}&returnurl=${window.location.href}`,
      title: intl.formatMessage({ id: "settings.carecentersettings" }),
    },
    {
      showLink: dropInProduct,
      url: dropInProduct && `${dropInProduct.productUrl}/carecenteradmin`,
      title:
        dropInProduct &&
        intl.formatMessage(
          { id: "settings.dropinsettings" },
          { productname: dropInProduct.productName }
        ),
    },
    {
      showLink: vaccinationProduct,
      url:
        vaccinationProduct &&
        `${vaccinationProduct.productUrl.replace(
          "/vaccination",
          ""
        )}/carecenteradmin/carecenteradmin`,
      title:
        vaccinationProduct &&
        intl.formatMessage(
          { id: "settings.dropinsettings" },
          { productname: vaccinationProduct.productName }
        ),
    },
    {
      showLink: isAdminUser,
      url: `${EXTERNAL_URLS.ADDCARECENTER}?returnurl=${window.location.href}`,
      title: intl.formatMessage({ id: "settings.addcarecenter" }),
    },
    {
      showLink: medicalRoundProduct && isAdminUser,
      url: medicalRoundProduct && `${medicalRoundProduct.productUrl}/admin`,
      title:
        medicalRoundProduct &&
        intl.formatMessage(
          { id: "settings.medicalroundadmin" },
          { productname: medicalRoundProduct.productName }
        ),
    },
  ];

  const logLinks = dropInProduct && [
    {
      showLink: true,
      url: `${dropInProduct.productUrl}/log/auditlog`,
      title: intl.formatMessage(
        { id: "logs.dropinlogs" },
        { productname: dropInProduct.productName }
      ),
    },
  ];

  const registratorLinks = dropInProduct && [
    {
      showLink: true,
      url: `${dropInProduct.productUrl}/Patient/SelectSymptomForm`,
      title: intl.formatMessage({ id: "registrator.register" }),
    },
    {
      showLink: remoteRegistrationEnabled,
      url: `${dropInProduct.productUrl}/Patient/CheckIn`,
      title: intl.formatMessage({ id: "registrator.checkin" }),
    },
    {
      showLink: remoteRegistrationEnabled,
      url: `${dropInProduct.productUrl}/Patient/RegistrationAndCheckIn`,
      title: intl.formatMessage({ id: "registrator.registerandcheckin" }),
    },
  ];

  const waitingRoomLinks = dropInProduct && [
    {
      showLink: true,
      url: `${dropInProduct.productUrl}/WaitingRoom`,
      title: intl.formatMessage({ id: "waitingroom.waitingroom" }),
    },
  ];

  const currentUrl = window.location.href.toLowerCase();

  return (
    <>
      <Row>
        <Col md={8}>
          <H1>
            <FormattedMessage
              id={"welcomeMessage"}
              values={{ name: oidc.oidcUser.profile.firstname }}
            />
          </H1>
          <H3>{selectedCareCenter && selectedCareCenter.name}</H3>
        </Col>
      </Row>
      {alerts && <AlertMessages alerts={alerts} />}
      {(isClinicUser || isAdminUser || hasSuperUserPrivileges) && (
        <>
          <Row>
            {dropInProduct ? (
              <PortalCard>
                <CardImage
                  image={dropInPicture}
                  title={dropInProduct.productName}
                  icon={dropInIcon}
                />
                <LinksWrapper>
                  {dropInLinks.map(
                    ({ showLink, url, title }) =>
                      showLink && (
                        <ProductFeatureLink
                          key={title}
                          url={url}
                          title={title}
                        />
                      )
                  )}
                </LinksWrapper>
              </PortalCard>
            ) : (
              <PortalCard isdisabled={"true"}>
                <CardImage
                  image={dropInPicture}
                  title={intl.formatMessage({ id: "dropin.cardheading" })}
                  icon={dropInIcon}
                  isDisabled={true}
                />
                <LinksWrapper isDisabled={true}>
                  <Link
                    href="https://www.collabodoc.com/direktmottagning"
                    target="_blank"
                    isDisabled={true}
                  >
                    <FontAwesomeIcon size="sm" icon={faChevronRight} />
                    <ProductFeatureTitle>
                      <FormattedMessage id={"knowmore.dropin"} />
                    </ProductFeatureTitle>
                  </Link>
                </LinksWrapper>
              </PortalCard>
            )}
            {digitalContactProduct ? (
              <PortalCard>
                <CardImage
                  image={contactPicture}
                  title={digitalContactProduct.productName}
                  icon={contactIcon}
                />
                <LinksWrapper>
                  {isIE ? (
                    <CardText>
                      <FontAwesomeIcon size="sm" icon={faChevronRight} />
                      <ProductFeatureTitle>
                        <FormattedMessage id={"IEnotsupported"} />
                      </ProductFeatureTitle>
                    </CardText>
                  ) : (
                    <>
                      {digitalContactLinks.map(
                        ({ showLink, url, title }) =>
                          showLink && (
                            <ProductFeatureLink
                              key={title}
                              url={url}
                              title={title}
                            />
                          )
                      )}
                    </>
                  )}
                </LinksWrapper>
              </PortalCard>
            ) : (
              <PortalCard isdisabled={"true"}>
                <CardImage
                  image={contactPicture}
                  title={intl.formatMessage({ id: "contact.cardheading" })}
                  icon={contactIcon}
                  isDisabled={true}
                />
                <LinksWrapper isDisabled={true}>
                  <Link
                    onClick={() => setShowDigitalContactModal(true)}
                    isDisabled={true}
                  >
                    <FontAwesomeIcon size="sm" icon={faChevronRight} />
                    <ProductFeatureTitle>
                      <FormattedMessage id={"knowmore.digitalcontact"} />
                    </ProductFeatureTitle>
                  </Link>
                </LinksWrapper>
              </PortalCard>
            )}
            {sickLeaveProduct ? (
              <PortalCard>
                <CardImage
                  image={sickLeavePicture}
                  title={sickLeaveProduct.productName}
                  icon={sickLeaveIcon}
                />
                <LinksWrapper>
                  {isIE ? (
                    <CardText>
                      <FontAwesomeIcon size="sm" icon={faChevronRight} />
                      <ProductFeatureTitle>
                        <FormattedMessage id={"IEnotsupported"} />
                      </ProductFeatureTitle>
                    </CardText>
                  ) : (
                    <>
                      {sickLeaveLinks.map(
                        ({ showLink, url, title }) =>
                          showLink && (
                            <ProductFeatureLink
                              key={title}
                              url={url}
                              title={title}
                            />
                          )
                      )}
                    </>
                  )}
                </LinksWrapper>
              </PortalCard>
            ) : (
              <PortalCard isdisabled={"true"}>
                <CardImage
                  image={sickLeavePicture}
                  title={intl.formatMessage({ id: "sickleave.cardheading" })}
                  icon={sickLeaveIcon}
                  isDisabled={true}
                />
                <LinksWrapper isDisabled={true}>
                  <Link
                    onClick={() => setShowSickLeaveModal(true)}
                    isDisabled={true}
                  >
                    <FontAwesomeIcon size="sm" icon={faChevronRight} />
                    <ProductFeatureTitle>
                      <FormattedMessage id={"knowmore.sickleave"} />
                    </ProductFeatureTitle>
                  </Link>
                </LinksWrapper>
              </PortalCard>
            )}
            {vaccinationProduct ? (
              <PortalCard>
                <CardImage
                  image={vaccinationPicture}
                  title={intl.formatMessage({ id: "vaccination.cardheading" })}
                  icon={vaccinationIcon}
                />
                <LinksWrapper>
                  {vaccinationLinks.map(
                    ({ showLink, url, title }) =>
                      showLink && (
                        <ProductFeatureLink
                          key={title}
                          url={url}
                          title={title}
                        />
                      )
                  )}
                </LinksWrapper>
              </PortalCard>
            ) : (
              <PortalCard isdisabled={"true"}>
                <CardImage
                  image={vaccinationPicture}
                  title={intl.formatMessage({ id: "vaccination.cardheading" })}
                  icon={vaccinationIcon}
                  isDisabled={true}
                />
                <LinksWrapper isDisabled={true}>
                  <Link
                    href="https://www.collabodoc.com/vaccination"
                    target="_blank"
                    isDisabled={true}
                  >
                    <FontAwesomeIcon size="sm" icon={faChevronRight} />
                    <ProductFeatureTitle>
                      <FormattedMessage id={"knowmore.vaccination"} />
                    </ProductFeatureTitle>
                  </Link>
                </LinksWrapper>
              </PortalCard>
            )}
            {digitalFirstProduct && (
              <PortalCard>
                <CardImage
                  image={digitalFirstPicture}
                  title={digitalFirstProduct.productName}
                  icon={digitalFirstIcon}
                />
                <LinksWrapper>
                  {isIE ? (
                    <CardText>
                      <FontAwesomeIcon size="sm" icon={faChevronRight} />
                      <ProductFeatureTitle>
                        <FormattedMessage id={"IEnotsupported"} />
                      </ProductFeatureTitle>
                    </CardText>
                  ) : (
                    <>
                      {digitalFirstLinks.map(
                        ({ showLink, url, title }) =>
                          showLink && (
                            <ProductFeatureLink
                              key={title}
                              url={url}
                              title={title}
                            />
                          )
                      )}
                    </>
                  )}
                </LinksWrapper>
              </PortalCard>
            )}
            {medicalRoundProduct ? (
              <PortalCard>
                <CardImage
                  image={medicalRoundPicture}
                  title={medicalRoundProduct.productName}
                  icon={medicalRoundIcon}
                />
                <LinksWrapper>
                  {medicalRoundLinks.map(
                    ({ showLink, url, title }) =>
                      showLink && (
                        <ProductFeatureLink
                          key={title}
                          url={url}
                          title={title}
                        />
                      )
                  )}
                </LinksWrapper>
              </PortalCard>
            ) : (
              <PortalCard isdisabled={"true"}>
                <CardImage
                  image={medicalRoundPicture}
                  title={intl.formatMessage({ id: "medicalround.cardheading" })}
                  icon={medicalRoundIcon}
                  isDisabled={true}
                />
                <LinksWrapper isDisabled={true}>
                  <Link
                    href="https://www.collabodoc.com/kontakt"
                    target="_blank"
                    isDisabled={true}
                  >
                    <FontAwesomeIcon size="sm" icon={faChevronRight} />
                    <ProductFeatureTitle>
                      <FormattedMessage id={"knowmore.medicalround"} />
                    </ProductFeatureTitle>
                  </Link>
                </LinksWrapper>
              </PortalCard>
            )}
            {videoProduct ? (
              <PortalCard>
                <CardImage
                  image={videoPicture}
                  title={videoProduct.productName}
                  icon={videoIcon}
                />
                <LinksWrapper>
                  {videoLinks.map(
                    ({ showLink, url, title }) =>
                      showLink && (
                        <ProductFeatureLink
                          key={title}
                          url={url}
                          title={title}
                        />
                      )
                  )}
                </LinksWrapper>
              </PortalCard>
            ) : (
              <PortalCard isdisabled={"true"}>
                <CardImage
                  image={videoPicture}
                  title={intl.formatMessage({ id: "video.cardheading" })}
                  icon={videoIcon}
                  isDisabled={true}
                />
                <LinksWrapper isDisabled={true}>
                  <Link
                    href="https://www.collabodoc.com/kontakt"
                    target="_blank"
                    isDisabled={true}
                  >
                    <FontAwesomeIcon size="sm" icon={faChevronRight} />
                    <ProductFeatureTitle>
                      <FormattedMessage id={"knowmore.video"} />
                    </ProductFeatureTitle>
                  </Link>
                </LinksWrapper>
              </PortalCard>
            )}
          </Row>
        </>
      )}
      {(hasSuperUserPrivileges || isAdminUser) && (
        <>
          <br />
          <H3>
            <FormattedMessage id={"adminHeader"} />
          </H3>
          <Row>
            <PortalCard>
              <CardImage
                image={userPicture}
                title={intl.formatMessage({ id: "user.cardheading" })}
                icon={userIcon}
              />
              <LinksWrapper>
                {adminLinks.map(
                  ({ showLink, url, title }) =>
                    showLink && (
                      <ProductFeatureLink key={title} url={url} title={title} />
                    )
                )}
              </LinksWrapper>
            </PortalCard>
            <PortalCard>
              <CardImage
                image={settingsPicture}
                title={intl.formatMessage({ id: "settings.cardheading" })}
                icon={settingsIcon}
              />
              <LinksWrapper>
                {settingsLinks.map(
                  ({ showLink, url, title }) =>
                    showLink && (
                      <ProductFeatureLink key={title} url={url} title={title} />
                    )
                )}
              </LinksWrapper>
            </PortalCard>
            {isAdminUser && dropInProduct && (
              <PortalCard>
                <CardImage
                  image={logsPicture}
                  title={intl.formatMessage({ id: "logs.cardheading" })}
                  icon={logsIcon}
                />
                <LinksWrapper>
                  {logLinks.map(
                    ({ showLink, url, title }) =>
                      showLink && (
                        <ProductFeatureLink
                          key={title}
                          url={url}
                          title={title}
                        />
                      )
                  )}
                </LinksWrapper>
              </PortalCard>
            )}
          </Row>
        </>
      )}
      {isRegistratorUser && dropInProduct && (
        <Row>
          <PortalCard>
            <CardImage
              image={registratorPicture}
              title={intl.formatMessage({ id: "registrator.cardheading" })}
              icon={registratorIcon}
            />
            <LinksWrapper>
              {registratorLinks.map(
                ({ showLink, url, title }) =>
                  showLink && (
                    <ProductFeatureLink key={title} url={url} title={title} />
                  )
              )}
            </LinksWrapper>
          </PortalCard>
          {hasWaitingRoom && (
            <PortalCard>
              <CardImage
                image={waitingRoomPicture}
                title={intl.formatMessage({ id: "waitingroom.cardheading" })}
                icon={waitingRoomIcon}
              />
              <LinksWrapper>
                {waitingRoomLinks.map(
                  ({ showLink, url, title }) =>
                    showLink && (
                      <ProductFeatureLink key={title} url={url} title={title} />
                    )
                )}
              </LinksWrapper>
            </PortalCard>
          )}
        </Row>
      )}
      {releaseNotes && (
        <>
          <Row>
            <HorizontalLine />
          </Row>
          <H3>
            <FormattedMessage id={"whatisnew"} />
          </H3>
          <ReleaseNotes releaseNotes={releaseNotes} />
        </>
      )}

      <Modal
        dialogClassName="video-modal"
        centered
        size="lg"
        onHide={() => setShowSickLeaveModal(!showSickLeaveModal)}
        show={showSickLeaveModal}
      >
        <ReactPlayer
          animation={false}
          controls={true}
          url="https://www.youtube.com/watch?v=OmixIzM8gFU&ab_channel=CollabodocAB?autoplay=0&rel=0"
        />
      </Modal>

      <Modal
        dialogClassName="video-modal"
        centered
        size="lg"
        onHide={() => setShowDigitalContactModal(!showDigitalContactModal)}
        show={showDigitalContactModal}
      >
        <ReactPlayer
          animation={false}
          controls={true}
          url="https://www.youtube.com/watch?v=twL14VIuVDw&ab_channel=CollabodocAB?autoplay=0&rel=0"
        />
      </Modal>

      {currentUrl.includes("isfirstlogin=true") && (
        <Modal
          centered
          size="lg"
          onHide={() => setShowSithModal(!showSithModal)}
          show={showSithModal}
        >
          <Modal.Header>
            <H2>Koppla ditt SITHS-kort</H2>
          </Modal.Header>
          <Modal.Body>
            <p>Du vet väl att du smidigt kan logga in med ditt SITHS-kort.</p>
            <p>
              På fliken Mitt konto kan du koppla ditt SITH-kort genom att klicka
              på "Hämta HSAid från SITHS-kortet" och följa instruktionerna.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <SecondaryButton onClick={() => setShowSithModal(!showSithModal)}>
              Avbryt
            </SecondaryButton>
            <PrimaryButton
              onClick={() => (window.location.href = EXTERNAL_URLS.MANAGE)}
            >
              Till Mitt konto
            </PrimaryButton>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const PortalCard = styled(Col).attrs(() => ({
  sm: 5,
  md: 4,
  lg: 3,
}))`
  padding: 0rem;
  margin: 1rem;
  box-shadow: ${({ theme, isdisabled }) =>
    isdisabled ? "0px 5px 15px #d1d1d1;" : theme.productCard.boxShadow};
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  color: white;
  align-self: end;
  margin: 10px 15px 12px 0;
  z-index: 1;
`;

const PortalCardText = styled(H5)`
  margin-bottom: 0;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  color: white;
  padding: 10px 0px 0px 10px;
  z-index: 1;
`;

const LinksWrapper = styled.div`
  border-top: 3px solid ${({ theme }) => theme.productCard.border.color};
  background-color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.productCard.disabled.backgroundColor
      : theme.productCard.backgroundColor};
  padding: 10px;
  min-height: 6.9rem;
  flex: 1;
`;

const Link = styled.a`
  display: block;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.productCard.disabled.color : theme.productCard.color};

  :hover {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.productCard.disabled.color : theme.productCard.color};
  }
`;

const CardText = styled.p`
  display: block;
  font-size: 15px;
  font-weight: 500;
`;

const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px 15px 0 0;
  position: relative;
  min-height: 8.4rem;
  background-color: black;
  filter: ${({ isDisabled }) => isDisabled && "grayscale(100%)"};

  ::before {
    border-radius: 15px 15px 0 0;
    opacity: 0.8;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${(props) => props.picture});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const CardImage = ({ image, title, icon, isDisabled }) => (
  <ImageDiv picture={image} isDisabled={isDisabled}>
    <PortalCardText>{title}</PortalCardText>
    <IconWrapper>{icon}</IconWrapper>
  </ImageDiv>
);

const ProductFeatureLink = ({ url, title }) => {
  return (
    <Link href={url}>
      <FontAwesomeIcon size="sm" icon={faChevronRight} />
      <ProductFeatureTitle>{title}</ProductFeatureTitle>
    </Link>
  );
};

const ProductFeatureTitle = styled.span`
  margin-left: 0.5rem;
`;

export default ProductsView;
