
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from "history";

const hasIKey = () => Boolean(window.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY);

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const applicationInsights = hasIKey() && new ApplicationInsights({
    config: {
        instrumentationKey: `${window.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY}`,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

const errorLogger = (error, errorInfo) => hasIKey() && applicationInsights.trackException({
    error: error,
    exception: error,
    severityLevel: SeverityLevel.Error,
    properties: { ...errorInfo }
});

const baseUrl = window.location.protocol + "//" + window.location.host;

hasIKey() && applicationInsights.loadAppInsights();
hasIKey() && applicationInsights.addTelemetryInitializer((envelope) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": baseUrl });
});

export { errorLogger };
