import sv from "../translations/sv.json";

const messages = {
    sv
}

const LOCALE = String(window.REACT_APP_LOCALE);
const getTranslations = () => messages[LOCALE];

export {LOCALE, getTranslations}
