export const CLINICROLE = {
  DOCTOR: "DoctorUser",
  NURSE: "NurseUser",
  PHYSIOTHERAPIST: "PhysiotherapistUser",
  PSYCHOLOGISTUSER: "PsychologistUser",
  COUNSELORUSER: "CounselorUser",
  OCCUPATIONALTHERAPISTUSER: "OccupationalTherapistUser",
  SECRETARY: "SecretaryUser",
  ASSISTANTNURSE: "AssistantNurseUser",
};

export const REGISTRATORROLE = {
  REGISTRATOR: "RegistratorUser",
};

export const ADMINISTRATORROLE = {
  SUPERUSER: "CarecenterAdmin",
  MANAGER: "ManagerUser",
  CLIENTADMIN: "ClientAdmin",
  ADMIN: "Admin",
};
export const clinicRoles = Object.values(CLINICROLE);
export const adminRoles = Object.values(ADMINISTRATORROLE);

/**
 *
 * @param userRoles The user's role or array of roles
 * @param role The role to check
 * @return {*|boolean}
 */
export const hasRole = (userRoles, role) => {
  return Array.isArray(userRoles)
    ? userRoles.includes(role)
    : userRoles === role;
};

/**
 *
 * @param userRoles The user's role or array of roles
 * @param roles An array of roles to check against.
 * @return {*}
 */
export const hasSomeRole = (userRoles, roles) => {
  return Array.isArray(userRoles)
    ? userRoles.some((r) => roles.includes(r))
    : roles.includes(userRoles);
};

/**
 *
 * @param userRoles The user's role or array of roles
 * @param roles An array of roles to get from
 * @return {*}
 */
const getRole = (userRoles, roles) => {
  return Array.isArray(userRoles)
    ? roles.find((role) => userRoles.includes(role))
    : roles.find((role) => userRoles === role);
};

export const hasClinicRole = (userRoles) => hasSomeRole(userRoles, clinicRoles);
export const hasSuperUserRole = (userRoles) =>
  hasRole(userRoles, ADMINISTRATORROLE.SUPERUSER);
export const hasAdminRole = (userRoles) =>
  hasRole(userRoles, ADMINISTRATORROLE.ADMIN);
export const hasRegistratorRole = (userRoles) =>
  hasRole(userRoles, REGISTRATORROLE.REGISTRATOR);
export const getClinicRole = (userRoles) => getRole(userRoles, clinicRoles);
export const getAdminRole = (userRoles) => getRole(userRoles, adminRoles);

/**
 * @deprecated Import functions directly instead
 */
export const useRoles = (userRoles) => {
  return {
    hasClinicRole: () => hasClinicRole(userRoles),
    hasSuperUserRole: () => hasSuperUserRole(userRoles),
    hasAdminRole: () => hasAdminRole(userRoles),
    hasRegistratorRole: () => hasRegistratorRole(userRoles),
    getClinicRole: () => getClinicRole(userRoles),
    getAdminRole: () => getAdminRole(userRoles),
    CLINICROLE,
    ADMINISTRATORROLE,
    REGISTRATORROLE,
  };
};
