const PRODUCT = {
  DROP_IN: 1,
  CONTACT: 2,
  SICK_LEAVE: 3,
  VACCINATION: 4,
  DIGITAL_FIRST: 5,
  MEDICAL_ROUND: 7,
  VIDEO: 8,
};

const DROPIN_SETTING = {
  REMOTE_REGISTRATION: 7,
  WAITING_ROOM: 9,
};

export { PRODUCT, DROPIN_SETTING };
