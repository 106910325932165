import React from "react";
import pict1 from "../images/Collabodoc01_FLIP.jpg"
import pict2 from "../images/Collabodoc02.jpg"
import pict4 from "../images/Collabodoc04_EDIT_FLIP.jpg"
import pict6 from "../images/Collabodoc06_EDIT.jpg"
import pict7 from "../images/Collabodoc07.jpg"
import pict10 from "../images/Collabodoc10.jpg"
import pict13 from "../images/Collabodoc13.jpg"
import pict191002 from "../images/collabodoc_191002_small_cut.jpg"
import pict14 from "../images/Collabodoc14_EDIT.jpg"
import digitalfirst from "../images/digitalfirst_portal_image.png";
import medicalround from "../images/Collabodoc03.jpg";
import video from "../images/video.png"
import ContactIcon from "../icons/ContactIcon";
import DropInIcon from "../icons/DropInIcon";
import SickLeaveIcon from "../icons/SickLeaveIcon";
import UserIcon from "../icons/UserIcon";
import SettingsIcon from "../icons/SettingsIcon";
import LogsIcon from "../icons/LogsIcon";
import WaitingRoomIcon from "../icons/WaitingRoomIcon";
import VaccinationIcon from "../icons/VaccinationIcon";
import MedicalRoundIcon from "../icons/MedicalRoundIcon";
import VideoIcon from "../icons/VideoIcon";


const dropInPicture = pict1;
const contactPicture = pict2;
const sickLeavePicture = pict191002;
const userPicture = pict4;
const settingsPicture = pict6;
const logsPicture = pict7;
const registratorPicture = pict13;
const waitingRoomPicture = pict10;
const vaccinationPicture = pict14;
const digitalFirstPicture = digitalfirst;
const medicalRoundPicture = medicalround;

const videoPicture = video;

const dropInIcon = <DropInIcon/>;
const digitalFirstIcon = <DropInIcon/>;
const contactIcon = <ContactIcon/>;
const sickLeaveIcon = <SickLeaveIcon/>;
const userIcon = <UserIcon/>;
const settingsIcon = <SettingsIcon/>;
const logsIcon = <LogsIcon/>;
const registratorIcon = <SickLeaveIcon/>;
const waitingRoomIcon = <WaitingRoomIcon/>;
const vaccinationIcon = <VaccinationIcon/>;
const medicalRoundIcon = <MedicalRoundIcon/>;

const videoIcon = <VideoIcon />

export {
    dropInIcon,
    contactIcon,
    sickLeaveIcon,
    userIcon,
    settingsIcon,
    logsIcon,
    registratorIcon,
    waitingRoomIcon,
    vaccinationIcon,
    digitalFirstIcon,
    medicalRoundIcon,
    videoIcon
}
export {
    dropInPicture,
    contactPicture,
    sickLeavePicture,
    userPicture,
    settingsPicture,
    logsPicture,
    registratorPicture,
    waitingRoomPicture,
    vaccinationPicture,
    digitalFirstPicture,
    medicalRoundPicture,
    videoPicture
}