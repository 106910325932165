import {
  OidcSecure,
  useReactOidc,
  withOidcUser,
} from "@axa-fr/react-oidc-context";
import {
  CareCenterContextProvider,
  ErrorBoundary,
  NavBar,
} from "@collabodoc/component-library";
import React, { useContext, useEffect, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Container } from "reactstrap";
import { ThemeContext, ThemeProvider } from "styled-components";
import { errorLogger } from "./applicationinsights";
import { getManuals } from "./Blob";
import FontProvider from "./fonts/fonts";
import Footer from "./Footer";
import ProductsView from "./ProductsView";
import { getTheme } from "./themes/getTheme";
import { API_URLS, EXTERNAL_URLS } from "./urls";
import UserManuals from "./UserManuals";
import { getTranslations, LOCALE } from "./utils/localeUtils";
import { Auth } from "./Auth";
import { CareCenterContext } from "@collabodoc/component-library";

const baseUrl = window.location.protocol + "//" + window.location.host;

const config = {
  isEnabled: process.env.NODE_ENV !== "test",
  client_id: window.REACT_APP_OIDC_CLIENT_ID,
  response_type: "code",
  post_logout_redirect_uri: `${baseUrl}/`,
  redirect_uri: `${baseUrl}/auth/signed-in`,
  silent_redirect_uri: `${baseUrl}/auth/signed-in-silent`,
  scope: `${process.env.REACT_APP_OIDC_SCOPE}`,
  authority: `${window.REACT_APP_USERSERVICE_URL}/identity`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  triggerAuthFlow: true,
};

const App = () => {
  const [manuals, setManuals] = useState(null);

  useEffect(() => {
    getManuals().then((result) => setManuals(result));
  }, []);

  const theme = getTheme();

  return (
    <IntlProvider locale={LOCALE} messages={getTranslations()}>
      <ThemeProvider theme={theme}>
        <FontProvider />
        <ErrorBoundary errorLogger={errorLogger}>
          <Auth config={config}>
            <OidcSecure>
              <CareCenterContextProviderWithUser
                apiUrl={API_URLS.GET_CARECENTERS}
                siteName={window.REACT_APP_CAREGIVER}
              >
                <BrowserRouter>
                  <Switch>
                    <>
                      <Container>
                        <NavBarContainer />
                        <Route exact path={"/"}>
                          <ProductsView />
                          <Footer />
                        </Route>
                        <Route path={"/usermanuals"}>
                          <UserManuals manuals={manuals}></UserManuals>
                        </Route>
                      </Container>
                    </>
                    <Redirect to={"/"} />
                  </Switch>
                </BrowserRouter>
              </CareCenterContextProviderWithUser>
            </OidcSecure>
          </Auth>
        </ErrorBoundary>
      </ThemeProvider>
    </IntlProvider>
  );
};

const NavBarContainer = () => {
  const { careCenters, setCurrentCareCenter, currentCareCenterId } =
    useContext(CareCenterContext);

  const intl = useIntl();
  const oidc = useReactOidc();
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const history = useHistory();

  const handleChange = (id) => {
    setCurrentCareCenter(id);
    history.push(pathname);
  };

  let dropdown = null;
  if (careCenters && careCenters.length > 1 && currentCareCenterId) {
    dropdown = {
      selected: careCenters.find((cc) => cc.id === currentCareCenterId).name,
      dropdownitems: careCenters.map((cc) => ({
        label: cc.name,
        id: cc.id,
        handler: () => handleChange(cc.id),
      })),
    };
  }

  const navbarItems = [
    {
      label: intl.formatMessage({ id: "navbar.myaccount" }),
      url: `${EXTERNAL_URLS.MANAGE}?returnUrl=${window.location.href}`,
    },
    {
      label: intl.formatMessage({ id: "navbar.logout" }),
      handler: oidc.logout,
    },
  ];
  return <NavBar logo={theme.logo} items={navbarItems} dropdown={dropdown} />;
};

const CareCenterContextProviderWithUser = withOidcUser(
  CareCenterContextProvider
);
export default App;
