import { wetterhalsan as wetterhalsanComponentLibraryTheme } from "@collabodoc/component-library";
import logo from '../images/Logo/wetterhalsan_light.png'


const wetterhalsan = {
    ...wetterhalsanComponentLibraryTheme,
    logo: logo,
    productCard: {
        backgroundColor: wetterhalsanComponentLibraryTheme.colors.focus,
        boxShadow: "0px 5px 15px #b1b1b1",
        disabled: {
            color: wetterhalsanComponentLibraryTheme.colors.black,
            backgroundColor: wetterhalsanComponentLibraryTheme.colors.disabled
        },
        border: {
            color: wetterhalsanComponentLibraryTheme.colors.white,
        },
        boxShadowColor: wetterhalsanComponentLibraryTheme.colors.primary,
        color: wetterhalsanComponentLibraryTheme.colors.white,
        hover: {
            color: wetterhalsanComponentLibraryTheme.colors.secondary,
            backgroundColor: wetterhalsanComponentLibraryTheme.colors.primary,
            border: {
                color: "#09385c"
            }
        },

    }
}

export default wetterhalsan;