import React from "react";

const ContactIcon = () => (
  <svg width="40px" height="40px" viewBox="0 0 78.32 77.11">
    <path
      d="M7469.64,1368.55a2.44,2.44,0,0,0,.06-.26c0-.1,0-.2,0-.3s0-.11,0-.17V1353a21.59,21.59,0,0,0,6.57-15.17c0-13.41-13.51-24.31-30.12-24.31s-30.12,10.9-30.12,24.31,13.51,24.31,30.12,24.31a37.68,37.68,0,0,0,5.3-.38l14.09,8.43.09,0a4.25,4.25,0,0,0,.49.21l.17,0a2.76,2.76,0,0,0,.65.09h0a2.59,2.59,0,0,0,.63-.08l.17,0a3.25,3.25,0,0,0,.43-.18l.18-.09a2.45,2.45,0,0,0,.41-.31l.1-.08a2.5,2.5,0,0,0,.4-.51s0,0,0,0,0-.1.07-.15a2.39,2.39,0,0,0,.13-.28C7469.59,1368.72,7469.61,1368.63,7469.64,1368.55Zm-18.05-12.38a32.51,32.51,0,0,1-5.39.46c-13.57,0-24.62-8.44-24.62-18.81s11.05-18.81,24.62-18.81,24.62,8.43,24.62,18.81a16.4,16.4,0,0,1-5.68,12s0,0,0,0a2.2,2.2,0,0,0-.2.23l-.16.18c-.05.08-.09.16-.13.24l-.13.23c0,.08,0,.16-.08.25a2,2,0,0,0-.08.26,1.72,1.72,0,0,0,0,.24l0,.3V1363l-10.78-6.45A2.7,2.7,0,0,0,7451.59,1356.17Z"
      transform="translate(-7397.99 -1313.51)"
      fill="currentColor"
    />
    <path
      d="M7453.69,1366a2.74,2.74,0,0,0-3.82.69c-4.31,6.18-12.65,10-21.76,10a32.51,32.51,0,0,1-5.39-.46h-.15l-.28,0H7422l-.24,0-.31.08-.2.06-.31.15-.11,0-10.78,6.45v-11.2c0-.1,0-.21,0-.31l0-.23-.09-.27-.08-.24-.13-.23a1.71,1.71,0,0,0-.13-.23,1.46,1.46,0,0,0-.16-.19,1.43,1.43,0,0,0-.2-.23l0,0a16.37,16.37,0,0,1-5.69-12c0-5,2.55-9.67,7.16-13.23a2.75,2.75,0,1,0-3.36-4.35c-6,4.62-9.3,10.86-9.3,17.58a21.59,21.59,0,0,0,6.57,15.17v14.83c0,.06,0,.12,0,.17s0,.2,0,.3l.06.26c0,.09.05.17.08.25s.09.19.13.28a1,1,0,0,1,.07.15l0,0a2.74,2.74,0,0,0,.4.5l.1.09a3.91,3.91,0,0,0,.41.3l.18.09a2.14,2.14,0,0,0,.44.18l.16,0a2.59,2.59,0,0,0,.63.08h0a2.74,2.74,0,0,0,.64-.09l.17,0a2.33,2.33,0,0,0,.49-.21l.1,0,14.09-8.44a36.12,36.12,0,0,0,5.29.39c10.89,0,21-4.75,26.27-12.39A2.75,2.75,0,0,0,7453.69,1366Z"
      transform="translate(-7397.99 -1313.51)"
      fill="currentColor"
    />
  </svg>
);

export default ContactIcon;
