import { H4 } from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ReactHtmlParser, { domToReact } from 'html-react-parser';
import { ThemeContext } from "styled-components";

const ReleaseNotes = ({ releaseNotes }) => {

    const theme = useContext(ThemeContext);

    const options = {
        replace: ({ attribs, children }) => {
            if (!attribs) return;

            if (attribs.class === 'link') {
                return (
                    <a href={attribs.href} target="_blank" rel="noopener noreferrer" style={{ color: theme.typography.linkColor }}>
                        {domToReact(children, options)}
                    </a>
                );
            }
        }
    };

    let colHelperSm = 12;
    let colHelperMd = 12;

    if (releaseNotes.section.length === 2) {
        colHelperSm = 6;
        colHelperMd = 6
    }
    else if (releaseNotes.section.length === 3) {
        colHelperSm=4;
        colHelperMd=4;
    }
    else if (releaseNotes.section.length === 4) {
        colHelperSm=3;
        colHelperMd=3;
    }


    return (
        <Row>
            {releaseNotes.section.map((section, key) => {
                return (
                    <Col xs={12} sm={colHelperSm} md={colHelperMd} key={key}>
                        <H4>{section.heading}</H4>
                        {ReactHtmlParser(section.paragraph, options)}
                        <br />
                        <br />
                    </Col>
                );
            })}
        </Row>
    );
}


export default ReleaseNotes;