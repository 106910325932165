import { doktorse as doktorseComponentLibraryTheme } from "@collabodoc/component-library";
import logo from "../images/Logo/doktorse_light.png";

const doktorse = {
  ...doktorseComponentLibraryTheme,
  logo: logo,
  productCard: {
    backgroundColor: "#cdf4c6",
    boxShadow: "0px 5px 15px #d1d1d1",
    disabled: {
      color: doktorseComponentLibraryTheme.colors.primary,
      backgroundColor: doktorseComponentLibraryTheme.colors.disabled,
    },
    border: {
      color: doktorseComponentLibraryTheme.colors.primary,
    },
    boxShadowColor: doktorseComponentLibraryTheme.colors.primary,
    color: doktorseComponentLibraryTheme.colors.primary,
    hover: {
      color: doktorseComponentLibraryTheme.colors.secondary,
      backgroundColor: doktorseComponentLibraryTheme.colors.primary,
      border: {
        color: "#09385c",
      },
    },
  },
};

export default doktorse;
