import React from "react";

const VideoIcon = () => (

    <svg width="40px" height="40px" viewBox="0 0 81.7 79.5">
        <path
            d="M7753.58,1475.17c0-.1,0-.21,0-.32a1.81,1.81,0,0,0,0-.23c0-.06,0-.12-.05-.18a3.4,3.4,0,0,0-.12-.34v0l-.09-.15a2.14,2.14,0,0,0-.17-.3,1.73,1.73,0,0,0-.17-.21l-.17-.19a2.11,2.11,0,0,0-.23-.19,1.33,1.33,0,0,0-.19-.14,1.57,1.57,0,0,0-.25-.14l-.22-.11a1.49,1.49,0,0,0-.3-.09,1.52,1.52,0,0,0-.2-.06,2.85,2.85,0,0,0-.41,0h-.13a2.71,2.71,0,0,0-.4,0l-.15,0a1,1,0,0,0-.23.07l-.31.1h0l-13.82,6.17v-3.66a2.75,2.75,0,0,0-2.75-2.75h-2.75a12.12,12.12,0,0,0,2-6.77,12.27,12.27,0,1,0-24.54,0,12.14,12.14,0,0,0,2,6.77h-9.7a12.12,12.12,0,0,0,2.05-6.77,12.27,12.27,0,1,0-22.51,6.77h-2.73a2.75,2.75,0,0,0-2.75,2.75v34.65a2.75,2.75,0,0,0,2.75,2.75h22.58L7686.06,1529a2.75,2.75,0,1,0,4.24,3.5l12-14.56v12.81a2.75,2.75,0,1,0,5.5,0V1518l12.05,14.58a2.75,2.75,0,1,0,4.24-3.5l-13.58-16.44h22.59a2.75,2.75,0,0,0,2.75-2.75v-3.66l13.82,6.18a2.74,2.74,0,0,0,1.12.23,2.68,2.68,0,0,0,1.5-.44,2.75,2.75,0,0,0,1.25-2.31v-34.68Zm-19.31,24.27h0a1.73,1.73,0,0,0-.32-.11l-.2-.07-.2,0-.35,0h0l-.16,0a2,2,0,0,0-.35,0l-.24.06c-.09,0-.18,0-.27.09l-.24.11c-.07,0-.16.08-.23.13l-.2.15-.22.18c-.06.06-.11.13-.17.2s-.12.13-.17.2a2.28,2.28,0,0,0-.17.3l-.09.15v0c-.05.11-.08.23-.12.34s0,.13-.05.19a1.6,1.6,0,0,0,0,.23,3.16,3.16,0,0,0,0,.32v5.17H7679.8V1478h3.59a2.75,2.75,0,0,0,2.75-2.75v-2.69a2.77,2.77,0,0,0-.83-2,6.7,6.7,0,0,1-2.06-4.85,6.77,6.77,0,1,1,13.54,0,6.7,6.7,0,0,1-2.06,4.85,2.74,2.74,0,0,0-.84,2v2.69a2.75,2.75,0,0,0,2.75,2.75h16.91a2.75,2.75,0,0,0,2.75-2.75v-2.69a2.74,2.74,0,0,0-.84-2,6.7,6.7,0,0,1-2.06-4.85,6.77,6.77,0,1,1,13.54,0,6.7,6.7,0,0,1-2.06,4.85,2.77,2.77,0,0,0-.83,2v2.69a2.75,2.75,0,0,0,2.75,2.75h3.59v5.15a2.76,2.76,0,0,0,3.88,2.51l13.81-6.17v26.17Z"
            transform="translate(-7674.3 -1453.41)"
            fill="currentColor"/>
    </svg>
);

export default VideoIcon;