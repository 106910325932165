import { BlobServiceClient } from "@azure/storage-blob";

const caregiver = window.REACT_APP_CAREGIVER;
const blobSasUrl = window.REACT_APP_STORAGE_ACCOUNT;
const blobServiceClient = new BlobServiceClient(blobSasUrl);

const getBlobAsString = async (container, blob) => {
  const containerName = container;
  const blobName = blob;
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(blobName);

  const downloadBlockBlobResponse = await blobClient.download();
  var downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

  async function blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

  return downloaded;
};

const getBlobAsFile = async (container) => {
  const containerName = container;
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const manuals = [];

  for await (const blob of containerClient.listBlobsFlat()) {
    const blobClient = containerClient.getBlobClient(blob.name);
    const blobject = {
      name: blob.name,
      url: blobClient.url,
    };
    manuals.push(blobject);
  }
  return manuals;
};

const getActiveMessages = (result, ccId) => {
  var parsed = JSON.parse(result);
  var activeAlerts = [];
  parsed.forEach((alert) => {
    if (!alert?.caregivers?.length) {
      withinDateRange(alert) && activeAlerts.push(alert);
    } else if (alert?.caregivers?.includes(caregiver)) {
      if (!alert?.carecenterids?.length || alert.caregivers.length > 1) {
        //if no care centers or more than one care giver --> show for all care centers
        withinDateRange(alert) && activeAlerts.push(alert);
      } else if (alert?.carecenterids?.includes(ccId)) {
        withinDateRange(alert) && activeAlerts.push(alert);
      }
    }
  });
  return activeAlerts;
};

const withinDateRange = (alert) => {
  var today = new Date(new Date().toDateString());
  var fromdate =
    alert.fromdate && new Date(new Date(alert.fromdate).toDateString());
  var todate = alert.todate && new Date(new Date(alert.todate).toDateString());

  if (today >= fromdate && !todate) {
    return true;
  } else if (!fromdate && today <= todate) {
    return true;
  } else if (!fromdate && !todate) {
    return true;
  } else if (today >= fromdate && today <= todate) {
    return true;
  } else {
    return false;
  }
};

export const getAlerts = async (ccId) => {
  return await getBlobAsString("alertmessages", "Alerts.txt").then((result) =>
    getActiveMessages(result, ccId)
  );
};

export const getReleaseNotes = async () => {
  return await getBlobAsString("releasenotes", "ReleaseNotes.txt").then(
    (result) => JSON.parse(result)
  );
};

export const getManuals = async () => {
  return await getBlobAsFile("usermanual");
};
