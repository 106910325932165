import { P } from "@collabodoc/component-library";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const AlertMessages = ({ alerts }) => {

    return (

        <Alerts>
            <Col>
                {alerts.map((alert, key) => {
                    return (
                        <Alert key={key} variant={alert.bootstrapalerttype}>
                            <Alert.Heading>{alert.heading}</Alert.Heading>
                            <P>{alert.paragraph}</P>
                        </Alert>
                    );
                })}
            </Col>
        </Alerts>
    );
};

const Alerts = styled(Row)`
    margin-top: 10px;
  `;

export default AlertMessages;