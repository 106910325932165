import React from "react";

const MedicalRoundIcon = () => (
    <svg width="38px" height="38px" viewBox="0 0 80.32 79.11">
        <path className="cls-1"
              d="M1116.49,662.58h-23.18v-3a2.75,2.75,0,0,0-5.5,0v3h-16.62v-3a2.75,2.75,0,0,0-5.5,0v3h-23.45a3.15,3.15,0,0,0-3.15,3.14v68.22a3.15,3.15,0,0,0,3.15,3.15h74.25a3.15,3.15,0,0,0,3.14-3.15V665.72A3.15,3.15,0,0,0,1116.49,662.58Zm-50.8,6.29v3a2.75,2.75,0,1,0,5.5,0v-3h16.62v3a2.75,2.75,0,1,0,5.5,0v-3h20v17.18h-68V668.87Zm-20.31,61.93v-39h68v39Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1063,697.51h-12.27a2.75,2.75,0,0,0,0,5.5H1063a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1063,708.84h-12.27a2.75,2.75,0,0,0,0,5.5H1063a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1063,720.17h-12.27a2.75,2.75,0,0,0,0,5.5H1063a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1085.49,697.51h-12.26a2.75,2.75,0,0,0,0,5.5h12.26a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1085.49,708.84h-12.26a2.75,2.75,0,0,0,0,5.5h12.26a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1085.49,720.17h-12.26a2.75,2.75,0,0,0,0,5.5h12.26a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1108,697.51h-12.26a2.75,2.75,0,0,0,0,5.5H1108a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1108,708.84h-12.26a2.75,2.75,0,0,0,0,5.5H1108a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
        <path className="cls-1" d="M1108,720.17h-12.26a2.75,2.75,0,0,0,0,5.5H1108a2.75,2.75,0,0,0,0-5.5Z"
              transform="translate(-1039.09 -656.84)"
              fill="currentColor"/>
    </svg>
);

export default MedicalRoundIcon;
