import React from "react";

const UserIcon = () => (
  <svg width="45px" height="45px" viewBox="0 0 107.17 81.27">
    <path
      d="M7628.83,1247.69a1.56,1.56,0,0,0,.16-.2c.05-.08.09-.16.13-.24a2.4,2.4,0,0,0,.11-.23c0-.09.06-.18.09-.27s.05-.16.07-.25a2.58,2.58,0,0,0,0-.29c0-.08,0-.14,0-.22v0s0,0,0,0c-.27-8.35-1.22-14.33-3-18.84-2.77-7-7.66-10.6-14.53-10.6a14.29,14.29,0,0,0-11.58,5.19l-.05.06-.1.11a1,1,0,0,1-.09.13l-.15.24c0,.08-.08.16-.12.24a1.84,1.84,0,0,0-.09.25,2,2,0,0,0-.08.26c0,.08,0,.17,0,.26a2.11,2.11,0,0,0,0,.26v.27c0,.08,0,.17,0,.26a2.21,2.21,0,0,0,.05.25,1.66,1.66,0,0,0,.1.29.74.74,0,0,0,0,.14c2.43,5.37,4.09,12.44,5.07,21.61l0,.14c0,.08,0,.16.05.25a2,2,0,0,0,.08.26l.09.23c0,.08.09.16.14.24l.12.2.18.22c.06,0,.11.11.16.16a1.24,1.24,0,0,0,.22.18l.18.14.26.14.2.1.32.1.17,0a3.17,3.17,0,0,0,.53,0h19.16l.24,0,.26,0,.27-.08a2.5,2.5,0,0,0,.25-.09,1.41,1.41,0,0,0,.24-.13l.22-.13.21-.17.19-.17A1.73,1.73,0,0,0,7628.83,1247.69Zm-7.51-18.61c1.34,3.4,2.15,8,2.49,14.16h-13.74a77.23,77.23,0,0,0-4.51-19.11,9,9,0,0,1,6.35-2.17C7616.48,1222,7619.38,1224.15,7621.32,1229.08Z"
      transform="translate(-7522.27 -1175.64)"
      fill="currentColor"
    />
    <path
      d="M7576.1,1210.13a17.25,17.25,0,1,0-17.24-17.25A17.27,17.27,0,0,0,7576.1,1210.13Zm0-29a11.75,11.75,0,1,1-11.74,11.74A11.76,11.76,0,0,1,7576.1,1181.14Z"
      transform="translate(-7522.27 -1175.64)"
      fill="currentColor"
    />
    <path
      d="M7611.85,1213.36a12.28,12.28,0,1,0-12.27-12.27A12.29,12.29,0,0,0,7611.85,1213.36Zm0-19a6.78,6.78,0,1,1-6.77,6.78A6.78,6.78,0,0,1,7611.85,1194.31Z"
      transform="translate(-7522.27 -1175.64)"
      fill="currentColor"
    />
    <path
      d="M7540.36,1213.36a12.28,12.28,0,1,0-12.27-12.27A12.28,12.28,0,0,0,7540.36,1213.36Zm0-19a6.78,6.78,0,1,1-6.77,6.78A6.78,6.78,0,0,1,7540.36,1194.31Z"
      transform="translate(-7522.27 -1175.64)"
      fill="currentColor"
    />
    <path
      d="M7599.7,1255.42a1,1,0,0,0,.12-.23,1.29,1.29,0,0,0,.08-.26,1.25,1.25,0,0,0,.07-.26,2.48,2.48,0,0,0,0-.27,2,2,0,0,0,0-.24s0,0,0-.05v0c-.67-19.93-4.07-40.31-24.18-40.31s-23.43,21.54-24.16,40.3a.43.43,0,0,1,0,0,.09.09,0,0,0,0,.05c0,.08,0,.15,0,.22a2.4,2.4,0,0,0,0,.28,7.62,7.62,0,0,0,.15.52c0,.08.08.16.12.24l.12.23c.05.08.11.14.16.21l.17.21.19.17.21.17.22.13.24.13a2.5,2.5,0,0,0,.25.09l.26.08.28,0,.22,0h43.05l.22,0,.29,0,.25-.08.26-.09a1,1,0,0,0,.23-.13,1.71,1.71,0,0,0,.23-.13l.21-.17.19-.17c.06-.06.11-.14.17-.21l.16-.2C7599.63,1255.58,7599.66,1255.5,7599.7,1255.42Zm-23.86-36.16c11.94,0,17.41,9.37,18.56,32.15h-37.08C7558.58,1228.92,7564.19,1219.26,7575.84,1219.26Z"
      transform="translate(-7522.27 -1175.64)"
      fill="currentColor"
    />
    <path
      d="M7551.93,1224.52a1.43,1.43,0,0,0,.09-.27,1.31,1.31,0,0,0,.06-.27,2.13,2.13,0,0,0,0-.25,2.62,2.62,0,0,0,0-.28c0-.08,0-.17,0-.25a2,2,0,0,0-.05-.26,2,2,0,0,0-.07-.26,1.86,1.86,0,0,0-.1-.25,2.56,2.56,0,0,0-.11-.24c-.05-.08-.1-.16-.16-.24a1,1,0,0,0-.09-.13l-.09-.11-.06-.06a14.29,14.29,0,0,0-11.58-5.19c-16.57,0-17.24,20.63-17.52,29.44v.09a1.64,1.64,0,0,0,0,.22,2.58,2.58,0,0,0,0,.29,1,1,0,0,0,.07.25,1.38,1.38,0,0,0,.08.27,2.53,2.53,0,0,0,.12.23c0,.08.08.16.13.24a1.49,1.49,0,0,0,.15.2c.06.07.11.15.17.21l.2.17a1.2,1.2,0,0,0,.21.17l.22.13a1.12,1.12,0,0,0,.24.13,1.84,1.84,0,0,0,.25.09l.26.08.27,0a1.75,1.75,0,0,0,.24,0h19.15a3.07,3.07,0,0,0,.53,0l.18,0,.32-.1.2-.1.25-.14a1.33,1.33,0,0,0,.19-.14,1.18,1.18,0,0,0,.21-.18c.06,0,.11-.11.16-.16l.18-.22a1.37,1.37,0,0,0,.13-.2c0-.08.09-.16.13-.25l.09-.22a2,2,0,0,0,.08-.26,1.23,1.23,0,0,0,.05-.25.7.7,0,0,0,0-.14,97.12,97.12,0,0,1,2.19-13,50.75,50.75,0,0,1,2.88-8.59A.44.44,0,0,0,7551.93,1224.52Zm-12.14-2.56a9.06,9.06,0,0,1,6.36,2.17,57.42,57.42,0,0,0-2.49,7.81,96.89,96.89,0,0,0-2,11.3h-13.74C7528.74,1228.36,7532.36,1222,7539.79,1222Z"
      transform="translate(-7522.27 -1175.64)"
      fill="currentColor"
    />
  </svg>

);

export default UserIcon;
